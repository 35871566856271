<template>
  <div style="width: 100%">
    <div v-if="filter === 'euro'">
    <span class="font-weight-bolder" v-if="variation !== 0">{{ amount|euro }}
      <sup class="font-weight-bolder badge py-0 px-1" :class="variation > 0? ' badge-outline-success': variation === 0? 'text-dark' : 'badge-outline-danger'">{{variation}}%</sup>
    </span>
      <span v-else class="font-weight-bolder" :class="amount < 0 ? 'text-danger': ''">{{ amount|euro }}</span>
    </div>
    <div v-if="filter === 'number'">
    <span class="font-weight-bolder" v-if="variation !== 0">{{ amount|number }}
      <sup class="font-weight-bolder badge py-0 px-1" :class="variation > 0? ' badge-outline-success': variation === 0? 'text-dark' : 'badge-outline-danger'">{{variation}}%</sup>
    </span>
      <span v-else class="font-weight-bolder" :class="amount < 0 ? 'text-danger': ''">{{ amount|number }}</span>
    </div>
    <div v-if="filter === null">
    <span class="font-weight-bolder" v-if="variation !== 0">{{ amount }}
      <sup class="font-weight-bolder badge py-0 px-1" :class="variation > 0? ' badge-outline-success': variation === 0? 'text-dark' : 'badge-outline-danger'">{{variation}}%</sup>
    </span>
      <span v-else class="font-weight-bolder" :class="amount < 0 ? 'text-danger': ''">{{ amount }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "_rows",
  props: {
    variation: {
      default: 0
    },
    amount: {
      default: 0
    },
    filter: {
      default: null
    }
  }
}
</script>

<style scoped>

</style>
